import { createRouter, createWebHistory } from 'vue-router'
import Books from './views/books/Books.vue'
import Book from './views/book/Book.vue'
import Download from './views/book/Download.vue'

const routes = [
  { path: '', redirect: '/books' },
  { path: '/books', component: Books },
  { path: '/book/:id', component: Book, props: true },
  { path: '/book/:id/download', component: Download, props: true },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
