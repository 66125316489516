<template>
</template>

<script>
export default {
  name: 'Downloader',
  props: {
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  mounted() {
    this.downloadFile()
  },
  methods: {
    downloadFile() {
      // create element <a> for download
      const link = document.createElement('a');
      link.href = this.url;
      link.target = '_blank';
      if (this.name) {
        link.download = this.name;
      }

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.$router.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
