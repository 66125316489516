import axios from "axios";

export function metis(config) {
  const instance = axios.create({
    baseURL: 'http://metis.taofanke.fun',
    timeout: 30000,
  })

  instance.interceptors.request.use(config => {
    return config
  }, err => {
    console.log(err)
  })

  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    console.log(err)
  })

  return instance(config)
}

export async function searchBooks(query, ext='', page=1) {
  query = query.replaceAll(':', ' ')
  const response = await metis({
    'url': '/api/books/search/?format=json&search=' + query + '&ext=' + ext + '&page=' + page
  })
  return response
}

export async function recommendBooks(page=1) {
  const response = await metis({
    'url': '/api/books/?format=json&page=' + page
  })
  return response
}

export async function getBook(id) {
  const response = metis({
    'url': '/api/books/' + id + '/'
  })
  return response
}
