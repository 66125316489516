<template>
  <div id="reader">
    <pre>
      {{ text }}
    </pre>
  </div>
</template>

<script>

export default {
  name: 'TextReader',
  mounted() {
    this.init()
  },
  data() {
    return {
      text: null,
    }
  },
  props: {
    url: null
  },
  methods: {
    async init() {
      fetch(this.url)
        .then(res => res.text())
        .then(text => this.text = text)
        .catch(e => console.error(e))
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#reader {
  height: 100vh;
  max-width: 1080px;
  margin: 1em auto;
  font-size: 1.2em;
  text-overflow: auto;
}
pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>
