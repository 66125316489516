import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import prettyBytes from 'pretty-bytes';
import ElementPlus from 'element-plus'
import "bootstrap"

import 'normalize.css/normalize.css'
import "bootstrap/dist/css/bootstrap.min.css"
import 'element-plus/dist/index.css'


const app = createApp(App)
app.config.globalProperties.$filters = {
  prettyBytes
}

app.use(ElementPlus)
app.use(router)
app.mount('#app')
