<template>
  <div class="ribbon ribbon-top-right">
    <span v-if="flag != null" :class="flagClass" @click="clickFile(false)">{{ flagAction }}</span>
  </div>
  <el-dialog v-model="centerDialogVisible" title="警告" width="30%" center>
    <span>
      关机之后将无法阅读和下载图书，但是依然可以搜索图书。你确认关机吗？
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="clickFile(true)">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElNotification } from 'element-plus'
import { metis } from '@/network/metis'

export default {
  name: 'FileRibbon',

  data: function() {
    return {
      timer: null,
      flag: null,
      centerDialogVisible: false,
    }
  },

  computed: {
    flagClass: function() {
      return {
        'success': this.flag
      }
    },
    flagAction: function() {
      return this.flag ? 'ON' : 'OFF'
    }
  },

  mounted() {
    this.timer = setInterval(() => {
      this.fileStatus();
    }, 2000);
  },

  unmounted() {
    clearInterval(this.timer)
  },

  methods: {
    async fileStatus() {
      const response = await metis('/api/file/status')
      this.flag = response.data.file_status == 0
    },

    async clickFile(confirm = false) {
      console.log(confirm);
      if (this.flag) {
        if (confirm) {
          await this.shutdownFile()
          this.centerDialogVisible = false
        } else {
          this.centerDialogVisible = true
        }
      } else {
        await this.wakeFile()
      }
    },

    async wakeFile() {
      if (!this.flag) {
        const response = await metis({
          url: '/api/file/wake',
          method: 'post'
        })
        ElNotification({
          title: 'Success',
          message: '成功发送开机指令',
          type: 'success',
        })
      }
    },

    async shutdownFile() {
      if (this.flag) {
        const response = await metis({
          url: '/api/file/shutdown',
          method: 'post'
        })
        ElNotification({
          title: 'Success',
          message: '成功发送关机指令，请稍等片刻',
          type: 'success',
        })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
:root {
  --size: calc(.8rem + 4vw);
  --wide: calc(1rem + .4vw);
  --font: calc(.5rem + .4vw);
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size);
  height: var(--size);
  background-color: transparent;
  overflow: hidden;
}

.ribbon span {
  display: flex;
  position: absolute;
  width: calc(2 * var(--size));
  height: var(--wide);
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  font-size: var(--font);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  background-color: rgba(0, 0, 0, .3);
  overflow: hidden;
  cursor: pointer;
}

.ribbon-top-right span {
  left: calc(var(--size) / -2 + (var(--wide) / 2) / 1.414);
  top: calc(var(--size) / 2 - (var(--wide) / 2) * 1.707);
  transform: rotate(45deg);
}

.ribbon span.success {
  background-color: var(--el-color-success);
}

</style>
