<template>
  <div class="container">
    <file-ribbon></file-ribbon>
    <header>
      <el-input v-model="query" class="m-2" clearable
        placeholder="请输入书名或者作者"
        ref='searchInput'
        @keyup.enter="getBooks(true)" />
      <el-select v-model="ext" class="m-2" placeholder="格式" clearable>
        <el-option
          v-for="ext in exts"
          :key="ext"
          :value="ext"
          :label="ext.toUpperCase()" />
      </el-select>
      <el-button class="m-2" @click="getBooks(true)">搜索</el-button>
    </header>

    <main>
      <el-table :data="books" stripe highlight-current-row style="width: 100%" resizable="false">
        <el-table-column prop="id" label="ID" width="90" />
        <el-table-column prop="title" label="书名" />
        <el-table-column prop="author" label="作者" width="250" />
        <el-table-column prop="pages" label="页数" width="60" align="right">
          <template #default="scope">
            {{ scope.row.pages || '' }}
          </template>
        </el-table-column>
        <el-table-column prop="size" label="大小" width="80" align="right">
          <template #default="scope">
            {{ $filters.prettyBytes(scope.row.size).toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column prop="ext" label="格式" width="70" align="center">
          <template #default="scope">
            {{ scope.row.ext.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click.prevent="openBook(scope.row)"
            >
              打开
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click.prevent="downloadBook(scope.row)"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </main>

    <footer>
      <el-pagination background hide-on-single-page
        layout="prev, pager, next"
        :total="total"
        :default-page-size="pageSize"
        v-model:current-page="page"
        @update:current-page="getBooks(false)">
      </el-pagination>
    </footer>

    <el-backtop :right="100" :bottom="100" />
    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023035418号</a>
    </div>
  </div>
</template>

<script>
import { recommendBooks, searchBooks } from '@/network/metis'
import FileRibbon from '@/components/FileRibbon.vue'

export default {
  name: 'Books',

  data: function() {
    return {
      query: '',
      ext: '',
      exts: [
        'pdf',
        'epub',
        'mobi',
        'azw3',
        'djvu',
      ],
      books: [],
      total: 0,
      page: 1,
      pageSize: 100,
    }
  },

  async created() {
    await this.getBooks()
  },

  mounted() {
    this.$nextTick(() => this.$refs.searchInput.focus())
  },

  components: {
    FileRibbon,
  },

  methods: {
    openBook(book) {
      this.$router.push({
        path: '/book/' + book.id,
      })
    },
    downloadBook(book) {
      this.$router.push({
        path: '/book/' + book.id + '/download',
      })
    },
    async getBooks(search) {
      if (search) {
        this.page = 1
      }
      if (this.query) {
        const response = await searchBooks(this.query, this.ext, this.page)
        this.books = response.results
        this.total = response.count
      } else {
        const response = await recommendBooks(this.page)
        this.books = response.results
        this.total = response.count
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  max-width: 960px;
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1080px;
  }
}

header {
  display: flex;
  padding: 1.5em 0;
}

footer {
  display: flex;
  justify-content: center;
  padding: 1.5em 0;
}

.beian {
  text-align: center;
}
</style>
