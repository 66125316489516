<template>
  <div id="reader">
  </div>
</template>

<script>
export default {
  name: 'DjvuReader',
  mounted() {
    this.init()
  },
  data() {
    return {
      text: null,
    }
  },
  props: {
    url: null
  },
  methods: {
    async init() {
      const viewer = new DjVu.Viewer();
      viewer.render(document.getElementById('reader'));
      await viewer.loadDocumentByUrl(this.url);
      viewer.configure({
        viewMode: 'continuous',
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#reader {
  height: 100vh;
  width: 100vw;
}
</style>
