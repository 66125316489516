<template>
  <div v-if="!url" class="loading" v-loading="true"></div>
  <pdf-reader v-else-if="ext == 'pdf'" style="height: 100vh;" :pdf="url" page-scale="page-fit"></pdf-reader>
  <text-reader v-else-if="ext == 'txt'" :url="url"></text-reader>
  <djvu-reader v-else-if="ext == 'djvu'" :url="url"></djvu-reader>
  <foliate-reader v-else-if="['epub', 'mobi', 'azw3', 'azw', 'fb2', 'fb2.zip', 'fbz', 'cbz'].indexOf(ext) != -1" :url="url"></foliate-reader>
  <downloader v-else :url="url" :name="title+'.'+ext"></downloader>
</template>

<script>
import { getBook } from '@/network/metis'
import PdfReader from "vue3-pdf-app";
import TextReader from '@/components/TextReader.vue'
import DjvuReader from '@/components/DjvuReader.vue'
import FoliateReader from '@/components/FoliateReader.vue'
import Downloader from '@/components/Downloader.vue'
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";

export default {
  name: 'Book',
  components: {
    PdfReader,
    TextReader,
    DjvuReader,
    FoliateReader,
    Downloader,
  },
  data: function() {
    return {
      name: null,
      ext: null,
      url: null,
    }
  },
  props: {
    id: null,
  },
  async created() {
    if (this.id) {
      await this.fetchBook(this.id)
    }
  },
  methods: {
    async fetchBook(id) {
      const response = await getBook(this.id)
      this.title = response.title
      this.ext = response.ext
      this.url = response.url
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
  height: 100vh;
}
</style>
