<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="Books">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import { ElButton, ElInput, ElLink, ElPagination, ElText } from 'element-plus'

export default {
  name: 'App',
  components: {
    ElButton,
    ElInput,
    ElLink,
    ElPagination,
    ElText,
  },
  methods: {
  }
}
</script>

<style>
:root {
  font-size: .9em;
}

a {
  text-decoration: none;
}

/* https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications */
iframe#webpack-dev-server-client-overlay{display:none!important}

</style>
