<template>
</template>

<script>
import { getBook } from '@/network/metis'

export default {
  name: 'Download',
  props: {
    id: null,
  },
  async created() {
    if (this.id) {
      await this.downloadBook(this.id)
    }
  },
  methods: {
    async downloadBook(id) {
      const response = await getBook(this.id)

      var link = document.createElement("a")
      link.target = "_blank"
      link.download = response.title
      link.href = response.url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      this.$router.go(-1)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
