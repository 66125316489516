<template>
  <div id="reader"></div>
</template>

<script>
import {View} from '@/vendor/foliate-js/view'

export default {
  name: 'FoliateReader',
  mounted() {
    this.init()
  },
  data() {
    return {
        view: null
    }
  },
  props: {
    url: null
  },
  methods: {
    async init() {
        fetch(this.url)
            .then(res => res.blob())
            .then(blob => this.createBook(new File([blob], new URL(this.url).pathname)))
            .then(book => this.openBook(book))
            .catch(e => console.error(e))
    },
    async createBook(file) {
        let book

        const isZip = async file => {
            const arr = new Uint8Array(await file.slice(0, 4).arrayBuffer())
            return arr[0] === 0x50 && arr[1] === 0x4b && arr[2] === 0x03 && arr[3] === 0x04
        }

        const isCBZ = ({ name, type }) =>
            type === 'application/vnd.comicbook+zip' || name.endsWith('.cbz')

        const isFB2 = ({ name, type }) =>
            type === 'application/x-fictionbook+xml' || name.endsWith('.fb2')

        const isFBZ = ({ name, type }) =>
            type === 'application/x-zip-compressed-fb2'
            || name.endsWith('.fb2.zip') || name.endsWith('.fbz')

        const makeZipLoader = async file => {
            const { configure, ZipReader, BlobReader, TextWriter, BlobWriter } =
                await import('@/vendor/foliate-js/vendor/zip.js')
            configure({ useWebWorkers: false })
            const reader = new ZipReader(new BlobReader(file))
            const entries = await reader.getEntries()
            const map = new Map(entries.map(entry => [entry.filename, entry]))
            const load = f => (name, ...args) =>
                map.has(name) ? f(map.get(name), ...args) : null
            const loadText = load(entry => entry.getData(new TextWriter()))
            const loadBlob = load((entry, type) => entry.getData(new BlobWriter(type)))
            const getSize = name => map.get(name)?.uncompressedSize ?? 0
            return { entries, loadText, loadBlob, getSize }
        }

        if (await isZip(file)) {
            const loader = await makeZipLoader(file)
            if (isCBZ(file)) {
                const { makeComicBook } = await import('@/vendor/foliate-js/comic-book.js')
                book = makeComicBook(loader, file)
            } else if (isFBZ(file)) {
                const { makeFB2 } = await import('@/vendor/foliate-js/fb2.js')
                const { entries } = loader
                const entry = entries.find(entry => entry.filename.endsWith('.fb2'))
                const blob = await loader.loadBlob((entry ?? entries[0]).filename)
                book = await makeFB2(blob)
            } else {
                const { EPUB } = await import('@/vendor/foliate-js/epub.js')
                book = await new EPUB(loader).init()
            }
        } else {
            const { isMOBI, MOBI } = await import('@/vendor/foliate-js/mobi.js')
            if (await isMOBI(file)) {
                const fflate = await import('@/vendor/foliate-js/vendor/fflate.js')
                book = await new MOBI({ unzlib: fflate.unzlibSync }).open(file)
            } else if (isFB2(file)) {
                const { makeFB2 } = await import('@/vendor/foliate-js/fb2.js')
                book = await makeFB2(file)
            }
        }
        return book
    },
    async openBook(book) {
        this.view = document.createElement('foliate-view')
        document.getElementById('reader').append(this.view)
        await this.view.open(book)
        await this.view.goTo(0)
        document.addEventListener('keydown', this.handleKeydown)
    },
    handleKeydown(event) {
        const k = event.key
        if (k === 'ArrowLeft' || k === 'h') this.view.goLeft()
        else if(k === 'ArrowRight' || k === 'l') this.view.goRight()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#reader {
    height: 100vh;
}
</style>
